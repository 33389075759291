.primary_btn {
    @apply bg-primary;
    width: 100%;
    height: 42px;
    display: grid;
    place-items: center;
    margin: 10px auto;
    cursor: pointer;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
}