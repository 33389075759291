.primary_btn {
  @apply bg-primary;
  width: 100%;
  height: 42px;
  display: grid;
  place-items: center;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
}

.secondary_btn {
  width: 100%;
  height: 42px;
  display: grid;
  place-items: center;
  margin: 10px auto;
  margin-top: -5px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  font-size: 13px;
}